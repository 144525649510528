import * as React from 'react'
import { graphql } from 'gatsby'
import InternalLayout from '../components/InternalLayout'
import Seo from '../components/Seo'
import {
  Flex,
  Box,
  Heading
} from '@chakra-ui/react'
import { PrismicRichText } from '@prismicio/react'
import SliceZone from '../components/SliceZone/SliceZone'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import Sidebar from '../components/Sidebar/Sidebar'
import MooseLottie from '../components/Footer/Moose/MooseLottie'
import { CustomLink } from '../utils/customLink'

const ProjectsSubpageTemplate = (props) => {
      // Explicitly delcare props and destructure the other properties:
      const { location, data } = props;
      const pageLocation = location.pathname

  if (!data) return null; 

  const document = data.prismicProjectsSubpage.data;
  const menuItems = null || document.sidebar_menu.document?.data.body;

  console.log(document)

    return (
    <InternalLayout>
      <Seo title={document.page_title.text}  />
      
     <Box position="relative" zIndex="1">
        <Flex w="100%" h="100%" flexWrap="wrap" flexDirection={{base: 'column-reverse', lg: 'row'}}>
            <Box w={{base: '100%', lg: '25%'}}>
              <Sidebar
                menuHeading={menuItems && document.sidebar_menu.document.data.menu_title.text} 
                menuItems={menuItems}
                pageLocation={pageLocation}
              />
            </Box>
          <Box w={{base: '100%', lg: '75%'}} bg="white" p="8" mb={{base: '4rem', md: '36rem'}}>
            <Heading as="h2" variant="page-title">
              {document.page_title.text}
            </Heading>
            <Heading as="h3" fontSize="2rem" variant="page-subtitle">
              {document.subtitle.text}
            </Heading>

        <PrismicRichText field={document.page_content.richText}
          components={{
          hyperlink: CustomLink,
        }}/>

        <SliceZone sliceZone={document.body}/>

        </Box>
        <MooseLottie />
        </Flex>
      </Box> 
    </InternalLayout>
  )
}



    export const query = graphql`
      query ProjectsSubPageQuery($uid: String!) {
        prismicProjectsSubpage(uid: { eq: $uid }) {
          _previewable
          uid
          type
          data {
            page_headline {
              text
            }
            page_title {
              text
            }
            page_content {
              richText
            }
            subtitle {
              text
            }
            sidebar_menu {
              document {
                ... on PrismicSidebarNavigation {
                  id
                  data {
                    body {
                      ... on PrismicSidebarNavigationDataBodySidebarNavAccordionItem {
                        id
                        slice_type
                        primary {
                          navigation_group_title {
                            text
                          }
                          title_link {
                            url
                            uid
                          }
                        }
                        items {
                          link {
                            url
                            uid
                          }
                          item_title {
                            text
                          }
                        }
                      }
                    }
                    menu_title {
                      text
                    }
                  }
                }
              }
            }
            body {
              ... on PrismicProjectsSubpageDataBodyContactCard {
                id
                slice_type
                primary {
                  department_contact_information {
                    text
                  }
                }
                items {
                  contact_title {
                    text
                  }
                  contact_phone {
                    text
                  }
                  contact_name {
                    text
                  }
                  contact_extension
                  contact_email {
                    text
                  }
                }
              }
              ... on PrismicProjectsSubpageDataBodyBidRfpProjectAccordion {
                id
                slice_type
                primary {
                  accordion_title {
                    text
                  }
                  accordion_subtitle {
                    text
                  }
                }
                items {
                  item_title {
                    text
                  }
                  item_description {
                    richText
                  }
                }
              }
              ... on PrismicProjectsSubpageDataBodyFormLinkModule {
                id
                slice_type
                primary {
                  form_links_title {
                    text
                  }
                }
                items {
                  form_links {
                    url
                  }
                }
              }
              ... on PrismicProjectsSubpageDataBodyRichText {
                id
                slice_type
                primary {
                  content {
                    richText
                  }
                }
              }
              ... on PrismicProjectsSubpageDataBodyRawHtmlSlice {
                id
                slice_type
                primary {
                  html_content {
                    html
                    richText
                    text
                  }
                }
              }
              ... on PrismicProjectsSubpageDataBodyIframeSlice {
                id
                primary {
                  iframe_source {
                    text
                  }
                }
                slice_type
              }
              ... on PrismicProjectsSubpageDataBodyMeetingTabs {
                id
                slice_type
                primary {
                  zoom_meeting_link {
                    text
                  }
                  vimeo_meeting_iframe_embed_code {
                    richText
                  }
                  upcoming_meeting_date(formatString: "MM/D/YYYY")
                  upcoming_meeting_agenda_packet {
                    richText
                  }
                  previous_meeting_date(formatString: "MM/D/YYYY")
                  link_to_upcoming_meeting_packet_pdf {
                    url
                  }
                  link_to_previous_meeting_packet_pdf {
                    url
                  }
                  link_to_previous_meeting_minutes_pdf {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `;

export default withPrismicPreview(ProjectsSubpageTemplate)
